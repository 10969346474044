<template lang="pug">
.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.flex.flex-col.w-module.p-4
  .flex.w-full.items-center.justify-between.mb-4
    .text-darkblue.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("objects_trailer_create") : $t("objects_trailer_change") }}
    .flex.justify-end
      img(
      src='../../assets/icons/history.svg',
      style='width: 18px; margin-right: 10px',
      v-if='notRole',
      @click='$emit("show-history", { objects: "units_trailers", objectId: trailer.id })'
    )
      i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .flex.mt-4.relative.col-start-1.col-end-3
    el-input(
      type='text',
      class='w-2/3',
      v-model='trailer.name',
      :class='{ "input-error": isNameMissing }'
    )
    skif-button.ml-6( v-if='!admin' width='24', @click='createOrUpdate') {{ title === 1 ? $t("create") : $t("change") }}
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='isNameMissing') {{ $t("objects.name") }}
  .flex.flex-col.mt-4.col-start-1.col-end-3
    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("code") }}
    el-input(type='text', class='w-2/3', v-model='trailer.code')
  .flex.flex-col.mt-4.col-start-1.col-end-3
    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("trailer.width") }}
    el-input(type='number', class='w-2/3', v-model='trailer.width')
  .flex.flex-col.mt-4.col-start-1.col-end-3
    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("description") }}
    el-input.mt-4(
      type='textarea',
      resize='none',
      maxlength=768,
      :rows='3',
      v-model='trailer.description'
    )
  add-custom.col-start-1.col-end-3(
    v-if='isAddingCf',
    @create='createCf',
    :title='1',
    @close='isAddingCf = false'
  )
  add-custom.col-start-1.col-end-3(
    v-if='isUpdatingCf',
    :cfToUpdate='cfToUpdate',
    :title='2',
    @update='updateCf',
    @close='isUpdatingCf = false'
  )
  .col-start-1.col-end-3.overflow-y-auto.mt-2(
    v-show='!isAddingCf && !isUpdatingCf'
  )
    .flex.justify-between.h-8
      span.font-semibold.text-headerText.text-sm.font-SourceSansPro {{ $t("objects_trailer_moreFields") }}
      button.focus_outline-none.relative.group(
        v-if='checkedCfs.length',
        type='button',
        @click='deleteCfs'
      )
        svg.group-hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
          width='24',
          height='24',
          xmlns='http://www.w3.org/2000/svg',
          fill='#91B4E7'
        )
          path(
            fill-rule='evenodd',
            clip-rule='evenodd',
            d='M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z'
          )
        skif-badge(list, :value='checkedCfs.length')
      svg.cursor-pointer(
        @click='isAddingCf = true',
        width='16',
        height='16',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z',
          fill='#91B4E7'
        )
    .table.w-full.table-fixed
      .table-header-group.text-left
        .table-row
          .table-cell.bg-white.top-0.sticky.z-10.w-10.border-b.border-reallylightblue
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllChecked',
              :indeterminate='isIndeterminate',
              :disabled='!trailer.custom_fields.length'
            )
          .table-cell.bg-white.top-0.sticky.border-b.border-reallylightblue
            span.font-bold.text-darkblue.text-base.mr-2.truncate {{ $t("objects_trailer_key") }}
          .table-cell.bg-white.top-0.sticky.border-b.border-reallylightblue
            span.font-bold.text-darkblue.text-base.mr-2.truncate {{ $t("value") }}
      .table-row-group
        .table-row.hover_bg-reallylightblue.cursor-pointer(
          v-for='cf in trailer.custom_fields',
          @click='showUpdate(cf)'
        )
          .table-cell.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedCfs',
              :label='cf.key',
              :showLabel='false'
            )
          .table-cell.truncate
            span.text-sm.font-bold.leading-10.transition-colors.duration-300(
              :class='checkedCfs.includes(cf.key) ? "text-darkblue" : "text-annotationColor"'
            ) {{ cf.name }}
          .table-cell.truncate
            span.text-sm.font-bold.leading-10.transition-colors.duration-300(
              :class='checkedCfs.includes(cf.key) ? "text-darkblue" : "text-annotationColor"'
            ) {{ cf.value }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    addCustom: () => import('./addCustomField.vue')
  },
  props: {
    title: Number,
    trailerToUpdate: {
      type: Object
    },
    admin: Boolean
  },
  data() {
    return {
      isNameMissing: false,
      checkedCfs: [],
      isAddingCf: false,
      isUpdatingCf: false,
      indexOfCf: 0,
      cfToUpdate: {},
      trailer: {}
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    isIndeterminate() {
      return (
        this.checkedCfs.length > 0 &&
        this.checkedCfs.length < this.trailer.custom_fields.length
      )
    },
    isAllChecked: {
      get() {
        return (
          this.checkedCfs.length >= this.trailer.custom_fields.length &&
          this.trailer.custom_fields.length
        )
      },
      set(value) {
        this.checkedCfs = value ? this.cfsKeys : []
      }
    },
    cfsKeys() {
      return this.trailer.custom_fields.map((k) => k.key)
    }
  },
  watch: {
    'trailer.name': function (val) {
      this.isNameMissing = val === ''
    }
  },
  methods: {
    valid() {
      let error = true
      if (this.trailer.name === '') {
        this.isNameMissing = true
        error = false
      }
      return error
    },
    createOrUpdate() {
      if (this.valid()) {
        this.title === 1
          ? this.$store
              .dispatch('trailers/CREATE', this.trailer)
              .then((_) => this.$emit('close'))
          : this.$store
              .dispatch('trailers/UPDATE', this.trailer)
              .then((_) => this.$emit('close'))
      }
    },
    deleteCfs() {
      this.trailer.custom_fields = this.trailer.custom_fields.filter(
        (cf) => this.checkedCfs.indexOf(cf.key) === -1
      )
      this.checkedCfs = []
    },
    updateCf(cf) {
      this.trailer.custom_fields[this.indexOfCf] = cf
      this.isUpdatingCf = false
    },
    createCf(cf) {
      this.trailer.custom_fields.push(cf)
      this.isAddingCf = false
    },
    showUpdate(cf) {
      this.cfToUpdate = cf
      this.indexOfCf = this.cfsKeys.indexOf(cf.key)
      this.isUpdatingCf = true
    }
  },
  created() {
    this.trailer =
      this.title === 1
        ? {
            name: 'Прицеп',
            code: '',
            description: '',
            custom_fields: [],
            width: ''
          }
        : JSON.parse(JSON.stringify(this.trailerToUpdate))
    if (this.admin) {
      console.log('its admin')
    }
  }
}
</script>
